import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.impresosgalarce.cl/api/',
  // baseURL: 'http://apiecommerce.test/api/',
  headers: {
    'Content-type': 'application/json',
  },
})

export default api