import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/inicio",
    name: "inicio",
    component: function () {
      return import("../views/InicioView.vue");
    },
  },
  {
    path: "/productos",
    name: "productos",
    component: function () {
      return import("../views/Productos/IndexView.vue");
    },
  },
  {
    path: "/productos/add",
    name: "productos-add",
    component: function () {
      return import("../views/Productos/AddView.vue");
    },
  },
  {
    path: "/ordenes",
    name: "ordenes",
    component: function () {
      return import("../views/Ordenes/IndexView.vue");
    },
  },
  {
    path: "/ordenes/ver/:idOrden",
    name: "ver-orden",
    component: function () {
      return import("../views/Ordenes/ViewView.vue");
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
