<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div
        class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div
            v-if="isAdmin"
            class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">No eres administrador!</span> Dirigete a
            la pagina principal e inicia sesion
          </div>
          <div
            v-if="isError"
            class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">Datos Erroneos!</span> Este usuario no
            existe en nuestra base de datos
          </div>
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            Inicia Sesion
          </h1>
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Usuario</label
            >
            <input
              v-model="credentials.email"
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div>
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Clave</label
            >
            <input
              v-model="credentials.password"
              type="password"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <button
            @click="login"
            class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Iniciar Sesion
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import api from "@/utils/api";
import { useAuthStore } from "../../stores/useAuthStore";
import { useRouter } from "vue-router";
const storeUser = useAuthStore();
const router = useRouter();

const isAdmin = ref(false);
const isError = ref(false);
const credentials = ref({
  email: "",
  password: "",
});

const login = () => {
  isAdmin.value = false;
  isError.value = false;
  api
    .post("login", credentials.value)
    .then((response) => {
      if (response.data.status == true) {
        console.log(response.data.data.isAdmin)
        if (response.data.data.isAdmin == 0) {
          isAdmin.value = true;
        } else {
          storeUser.setUser(
            true,
            response.data.data,
            response.data.token.plainTextToken
          );
          router.push("/inicio");
        }
      }
    })
    .catch((e) => {
      console.log(e.message);
      isError.value = true;
    });
};
</script>
