import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    token: null,
    session: null,
  }),
  getters: {
    
  },
  actions: {
    setUser(session, user, token) {
      this.token = token;
      this.user = user;
      this.session = session;
    },
    logout()
    {
      this.user = null,
      this.token = null,
      this.session = null
    }
  },
  persist: true,
});
